import "./VB.css";
import React from "react";
import VoiceBotIframe from "./VoiceBotiFrame";

function VB() {

  return (
    <div className="VB-container">
      <h1 className="VB-header-title">iSpeakWell</h1>
      <div className="VB-VoiceBot-container">
        <VoiceBotIframe />
      </div>
    </div>
  );
}
export default VB;